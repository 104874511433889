<template>
    <b-card no-body class="mb-2">
        <b-card-header class="align-items-center">
            <h4 class="mr-auto mb-0">
                <unicon
                    name="bookmark"
                    width="18"
                    height="18"
                    class="mr-1"
                ></unicon>
                {{ id != nullGuid ? "تفاصيل المنتج" : "إضافة منتج" }}
            </h4>
        </b-card-header>
        <ValidationObserver ref="productDto">
            <b-form>
                <b-card-body>
                    <b-card-text>
                        <b-row>
                            <b-col cols="12" lg="6">
                                <EKInputText
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم المنتج مطلوب'
                                        }
                                    ]"
                                    label="اسم المنتج"
                                    placeholder="ادخل اسم المنتج"
                                    name="name"
                                    v-model="productDto.name"
                                />
                                <EKInputSelect
                                    label="تصنيفات المنتج"
                                    placeholder="اختر تصنيفات للمنتج"
                                    :options="categoriesList"
                                    name="categoriesIds"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message:
                                                'يرجى اختيار تصنيفات للمنتج'
                                        }
                                    ]"
                                    multiple
                                    v-model="productDto.categoriesIds"
                                />
                                <label class="mt-2"> المواصفات </label>
                                <hr />
                                <EKInputTextarea
                                    label="الوصف"
                                    v-model="productDto.description"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'يرجى ملىء وصف المنتج'
                                        }
                                    ]"
                                    placeholder="ادخل وصف المنتج"
                                    name="description"
                                    rows="4"
                                />
                                <div
                                    class="d-flex align-items-center justify-content-between"
                                >
                                    <label>استخدامات المنتج</label>
                                    <b-button
                                        variant="primary"
                                        class="rounded-circle p-75"
                                        @click="addProductUse()"
                                    >
                                        <unicon
                                            name="plus"
                                            width="18"
                                            height="18"
                                            fill="#fff"
                                        />
                                    </b-button>
                                </div>
                                <b-list-group class="mb-1">
                                    <div
                                        class="bg-white p-0"
                                        v-for="(use, index) in productDto.uses"
                                        :key="index"
                                    >
                                        <div class="d-flex align-items-center">
                                            <EKInputText
                                                :key="'use' + '_value_' + index"
                                                :label="
                                                    'الاستخدام ' + (index + 1)
                                                "
                                                placeholder="ادخل الاستخدام"
                                                class="w-100 mr-1"
                                                :rules="[
                                                    {
                                                        type: 'required',
                                                        message: 'الحقل مطلوب'
                                                    }
                                                ]"
                                                :name="
                                                    'use' + '_value_' + index
                                                "
                                                v-model="use.use"
                                            />
                                            <b-button
                                                variant="primary"
                                                class="rounded-circle p-75 mt-2"
                                                @click="
                                                    productDto.uses.splice(
                                                        index,
                                                        1
                                                    )
                                                "
                                            >
                                                <unicon
                                                    name="minus"
                                                    width="18"
                                                    height="18"
                                                    fill="#fff"
                                                />
                                            </b-button>
                                        </div>
                                    </div>
                                </b-list-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group>
                                    <label class="p-50 m-0"
                                        >تصميم ثلاثي الابعاد للمنتج</label
                                    >
                                    <b-form-file @input="uploadFbx" />
                                    <b-button
                                        variant="primary"
                                        block
                                        class="my-1"
                                        @click="display3D"
                                        v-if="!visible3D"
                                    >
                                        عرض التصميم ثلاثي الأبعاد
                                        <unicon
                                            fill="#fff"
                                            name="cube"
                                            class="mx-1"
                                        ></unicon>
                                    </b-button>
                                    <model-viewer
                                        v-if="
                                            `${$store.getters['app/domainHost']}/${productDto.path3D}` &&
                                                visible3D
                                        "
                                        class="w-100"
                                        style="height: 400px;  "
                                        :poster="
                                            productDto.images[0].path.replace(
                                                /^/,
                                                $store.getters[
                                                    'app/domainHost'
                                                ] + '/'
                                            )
                                        "
                                                color="#000"
                                        :src="
                                            `${$store.getters['app/domainHost']}/${productDto.path3D}`
                                        "
                                        ar
                                        ar-modes="webxr scene-viewer quick-look"
                                        camera-controls
                                        environment-image="neutral"
                                        shadow-intensity="1.8"
                                        exposure="1.08"
                                        auto-rotate
                                    >
                                        <div
                                            class="progress-bar hide"
                                            slot="progress-bar"
                                        >
                                            <div class="update-bar"></div>
                                        </div>
                                        <button slot="ar-button" id="ar-button">
                                            View in your space
                                        </button>
                                        <div id="ar-prompt">
                                            <img
                                                src="https://modelviewer.dev/shared-assets/icons/hand.png"
                                            />
                                        </div>
                                    </model-viewer>
                                </b-form-group>
                                <EKInputImage
                                    ref="newImgsInput"
                                    label="إضافة صور للمنتج"
                                    :required="!productDto.images.length"
                                    noDeleteBtn
                                    mul
                                    title="أرفق الملفات هنا أو اضغط لتحميل"
                                    :value="productDto.files"
                                    @input="addNewFiles($event)"
                                >
                                    <template
                                        slot="preview"
                                        slot-scope="{ previewBase }"
                                    >
                                        <b-row class="px-2">
                                            <b-col
                                                cols="12"
                                                lg="4"
                                                v-for="(img,
                                                index) in previewBase"
                                                :key="index"
                                                class="p-2"
                                            >
                                                <button
                                                    @click="
                                                        previewBase.splice(
                                                            index,
                                                            1
                                                        );
                                                        cancelFile(index);
                                                    "
                                                    class="close AClass mt-50"
                                                    type="button"
                                                >
                                                    <span style="color: red"
                                                        >&times;</span
                                                    >
                                                </button>
                                                <img
                                                    :src="img"
                                                    class="img-fluid rounded product-img"
                                                    height="190"
                                                />
                                            </b-col>
                                        </b-row>
                                    </template>
                                </EKInputImage>
                                <div
                                    v-if="productDto.images.length"
                                    class="my-3"
                                >
                                    <h5>صور المنتج الحالية</h5>
                                    <b-row
                                        class="mx-50"
                                        style="border: dashed 2px #196da7"
                                    >
                                        <b-col
                                            cols="12"
                                            lg="4"
                                            v-for="(img,
                                            index) in productDto.images"
                                            :key="index"
                                            class="p-2"
                                        >
                                            <button
                                                @click="deleteImg(index)"
                                                class="close AClass mt-50"
                                                type="button"
                                            >
                                                <span style="color: red"
                                                    >&times;</span
                                                >
                                            </button>
                                            <img
                                                :src="
                                                    img.path.replace(
                                                        /^/,
                                                        $store.getters[
                                                            'app/domainHost'
                                                        ] + '/'
                                                    )
                                                "
                                                class="img-fluid rounded product-img"
                                                height="190"
                                            />
                                        </b-col>
                                    </b-row>
                                </div>
                                <b-row class="align-items-center">
                                    <b-col cols="12" lg="6">
                                        <EKInputText :rules="[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'الحد الأدنى للطلب مطلوب'
                                                },
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     label="الحد الأدنى للطلب (داخلي"
                                                     placeholder="ادخل الحد الأدنى للطلب)"
                                                     name="limit"
                                                     type="number"
                                                     v-model="productDto.limit" />
                                    
                                        <EKInputText :rules="[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'الحد الأدنى للطلب مطلوب'
                                                },
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     label="الحد الأدنى للطلب (الخارجي)"
                                                     placeholder="ادخل الحد الأدنى للطلب"
                                                     name="limitOtside"
                                                     type="number"
                                                     v-model="productDto.limitOutside" />
                                    </b-col>
                                    <b-col cols="12" lg="6" class="d-flex mt-2">
                                        <b-checkbox
                                            switch
                                            v-model="productDto.foodSuitability"
                                        ></b-checkbox>
                                        <label>يصلح للإستخدام الغذائي</label>
                                    </b-col>
                                </b-row>

                                <b-row class="align-items-center pt-2">
                                    <b-col cols="12" lg="3">
                                        <EKInputSelect
                                            label="الإضافات"
                                            placeholder="اختر إضافة للمنتج"
                                            :options="
                                                addonsList.filter(
                                                    addon =>
                                                        productDto.attributes.findIndex(
                                                            attr =>
                                                                attr.attributeId ==
                                                                addon.id
                                                        ) == -1
                                                )
                                            "
                                            name="attributeId"
                                            v-model="addonDto.attributeId"
                                        />
                                    </b-col>
                                    <b-col cols="6" lg="3" class="pl-0">
                                        <EKInputText
                                            label="السعر بالعملة الأجنبية"
                                            name="dollarValue"
                                            fieldClass="measRadius"
                                            class="textCenter"
                                            type="number"
                                            v-model="addonDto.dollarValue"
                                        />
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        lg="3"
                                        class="pt-2 text-center text-lg-left"
                                    >
                                        <b-button
                                            v-b-tooltip.hover
                                            title="إضافة جديدة"
                                            variant="primary"
                                            class="rounded p-75"
                                            @click="addAddon(addonDto)"
                                        >
                                            <unicon
                                                name="plus"
                                                width="18"
                                                height="18"
                                                fill="#fff"
                                            />
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row
                                    v-for="(addon,
                                    index) in productDto.attributes"
                                    :key="index"
                                    class="align-items-center"
                                >
                                    <b-col cols="12" lg="3" class="pt-1">
                                        <EKInputSelect
                                            placeholder="اختر إضافة للمنتج"
                                            :options="addonsList"
                                            :name="'attributeId' + index"
                                            :rules="[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'يرجى اختيار إضافة للمنتج'
                                                }
                                            ]"
                                            disabled
                                            v-model="addon.attributeId"
                                        />
                                    </b-col>
                                    <b-col cols="6" lg="3" class="pl-0">
                                        <EKInputText
                                            :rules="[
                                                {
                                                    type: 'required',
                                                    message: 'الحقل مطلوب'
                                                },
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                            :name="'dollarValue' + index"
                                            fieldClass="measRadius"
                                            class="textCenter"
                                            type="number"
                                            v-model="addon.dollarValue"
                                        />
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        lg="3"
                                        class="text-center text-lg-left"
                                    >
                                        <b-button
                                            variant="primary"
                                            class="rounded p-75"
                                            @click="removeAddon(addon)"
                                        >
                                            <unicon
                                                name="minus"
                                                width="18"
                                                height="18"
                                                fill="#fff"
                                            />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="12">
                                <hr />
                                <div class="d-flex align-items-center justify-content-between my-2">
                                    <h3>جدول مقاسات المنتج</h3>
                                    <b-col cols="12" lg="6" class="d-flex mt-2">
                                        <b-checkbox switch
                                                    v-model="productDto.isCircleShape"></b-checkbox>
                                        <label>المنتج اسطواني؟</label>
                                    </b-col>
                                    <b-button v-b-tooltip.hover
                                              title="إضافة مقاس جديد"
                                              variant="primary"
                                              class="rounded-circle p-75"
                                              @click="addMeasurement">
                                        <unicon name="plus"
                                                width="18"
                                                height="18"
                                                fill="#fff" />
                                    </b-button>
                                </div>
                                
                                <b-table responsive
                                         striped
                                         borderless
                                         :items="productDto.measurementCurrencies"
                                         :fields="fields">
                                    <template #cell(image)="data">
                                        <img v-b-tooltip.hover
                                             title="إضغط لتعديل صورة المقاس"
                                             @click="setModalImage(data)"
                                             :src="
                                                data.item.base
                                                    ? data.item.base
                                                    : data.item.path
                                                    ? $store.getters[
                                                          'app/domainHost'
                                                      ] +
                                                      '/' +
                                                      data.item.path
                                                    : '/media/placeholder/image-placeholder.jpg'
                                            "
                                             width="50"
                                             height="50"
                                             class="rounded-circle"
                                             style="object-fit: cover" />
                                        <small class="text-danger text-nowrap"
                                               v-if="
                                                !data.item.path &&
                                                    !data.item.base
                                            ">الصورة إجبارية</small>
                                    </template>
                                    <template #cell(length)="data">
                                        <EKInputText :rules="[
                                                {
                                                    type: 'required',
                                                    message: 'الحقل مطلوب'
                                                },
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     fieldClass="measRadius"
                                                     class="textCenter"
                                                     :name="'length' + data.index"
                                                     type="number"
                                                     v-model="data.item.length" />
                                    </template>
                                    <template #cell(width)="data">
                                        <EKInputText :rules="[
                                                {
                                                    type: 'required',
                                                    message: 'الحقل مطلوب'
                                                },
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     :name="'width' + data.index"
                                                     fieldClass="measRadius"
                                                     class="textCenter"
                                                     type="number"
                                                     v-model="data.item.width" />
                                    </template>
                                    <template #cell(height)="data">
                                        <EKInputText :rules="[
                                                {
                                                    type: 'required',
                                                    message: 'الحقل مطلوب'
                                                },
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     fieldClass="measRadius"
                                                     class="textCenter"
                                                     :name="'height' + data.index"
                                                     type="number"
                                                     v-model="data.item.height" />
                                    </template>
                                    <template #cell(capacityLiter)="data">
                                        <EKInputText :name="'capacityLiter' + data.index"
                                                     :rules="[
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     fieldClass="measRadius"
                                                     class="textCenter"
                                                     type="number"
                                                     v-model="data.item.capacityLiter" />
                                    </template>
                                    <template #cell(capacityKilo)="data">
                                        <EKInputText :name="'capacityKilo' + data.index"
                                                     :rules="[
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     fieldClass="measRadius"
                                                     class="textCenter"
                                                     type="number"
                                                     v-model="data.item.capacityKilo" />
                                    </template>
                                    <template #cell(dollarValue)="data">
                                        <EKInputText :rules="[
                                                {
                                                    type: 'required',
                                                    message: 'الحقل مطلوب'
                                                },
                                                {
                                                    type: 'min_value:0',
                                                    message: 'ادخل قيمة موجبة'
                                                }
                                            ]"
                                                     :name="'dollarValue' + data.index"
                                                     fieldClass="measRadius"
                                                     class="textCenter"
                                                     type="number"
                                                     v-model="data.item.dollarValue" />
                                    </template>
                                    <template #cell(delete)="data">
                                        <span style="padding: 4px 22px !important; cursor: pointer"
                                              @click="
                                                removeMeasurement(data.index)
                                            ">
                                            <unicon name="trash-alt"
                                                    fill="#000"
                                                    width="16" />
                                        </span>
                                    </template>
                                </b-table>

                                <div class="text-center">
                                    <small
                                        class="text-danger"
                                        v-if="
                                            !productDto.measurementCurrencies
                                                .length
                                        "
                                        >يرجى إضافة مقاس واحد على الأقل</small
                                    >
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-text>
                    <b-modal v-model="imageModal" title="تعديل صورة المقاس">
                        <EKInputImage
                            v-if="imageDto.item"
                            :value="
                                imageDto.item.base
                                    ? imageDto.item.base
                                    : imageDto.item.path
                                    ? $store.getters['app/domainHost'] +
                                      '/' +
                                      imageDto.item.path
                                    : '/media/placeholder/image-placeholder.jpg'
                            "
                            @input="file = $event"
                            ref="imageModalRef"
                        ></EKInputImage>
                        <template #modal-footer>
                            <b-button
                                variant="primary"
                                @click="setSubImage(file)"
                                >تم</b-button
                            >
                            <b-button
                                variant="outline-secondary"
                                @click="imageModal = false"
                                >إلغاء</b-button
                            >
                        </template>
                    </b-modal>
                </b-card-body>
                <b-card-footer class="py-1 d-flex">
                    <b-button
                        v-if="id != nullGuid"
                        class="mr-1"
                        @click="submitUpdateProduct()"
                        variant="primary"
                    >
                        تعديل
                    </b-button>
                    <b-button
                        v-else
                        class="mr-1"
                        @click="submitAddProduct()"
                        variant="primary"
                    >
                        إضافة</b-button
                    >
                    <b-button variant="outline-primary" to="./">تراجع</b-button>
                    <b-button
                        variant="outline-danger"
                        class="ml-auto"
                        @click="deleteProduct(productDto.id)"
                        v-if="id != nullGuid"
                        >حذف</b-button
                    >
                </b-card-footer>
            </b-form>
        </ValidationObserver>
    </b-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapActions, mapState } from "vuex";
import { nullGuid } from "@/EK-core/util/global";
import { handleDashLangChange } from "@/libs/event-bus";
// import threeViwer from "@/views/components/three-viwer.vue";
// import googleViwer from "@/views/components/google-viewer.vue";
export default {
    components: {
        // googleViwer,
        ValidationObserver,
        EKInputImage,
        EKInputTextarea,
        EKInputSelect,
        EKInputText
        // threeViwer
    },
    data: () => ({
        nullGuid,
        visible3D: false,
        addonDto: {
            id: nullGuid,
            attributeId: null,
            dollarValue: 0,
            syrianValue: null
        },
        fields: [
            {
                key: "image",
                label: "الصورة",
                sortable: false,
                class: "text-center"
            },
            {
                key: "length",
                label: "الطول (القطر العلوي) (سم)",
                sortable: false,
                class: "text-center"
            },
            {
                key: "width",
                label: "العرض (القطر السفلي) (سم)",
                sortable: false,
                class: "text-center"
            },
            {
                key: "height",
                label: "الارتفاع (سم)",
                sortable: false,
                class: "text-center"
            },
            {
                key: "capacityLiter",
                label: "سعة ب ليتر",
                sortable: false,
                class: "text-center"
            },
            {
                key: "capacityKilo",
                label: "سعة ب كغ",
                sortable: false,
                class: "text-center"
            },
            {
                key: "dollarValue",
                label: "السعر بالعملة الاجنبية",
                sortable: false,
                class: "text-center"
            },
            {
                key: "delete",
                label: "حذف",
                sortable: false,
                class: "text-center"
            }
        ],
        file: null,
        imageModal: false,
        imageDto: {}
    }),
    props: {
        id: String
    },
    mounted() {
        this.getProductDetails(this.id);
        handleDashLangChange(() => {
            this.getProductDetails(this.id);
        });
    },
    computed: {
        ...mapState({
            productDto: state => state.product.productDto,
            categoriesList: state => state.product.categoriesList,
            addonsList: state => state.product.addonsList
        })
    },
    methods: {
        ...mapActions([
            "getProductDetails",
            "addProduct",
            "updateProduct",
            "deleteProduct"
        ]),
        display3D() {
            this.visible3D = true;
        },
        uploadFbx(e) {
            this.productDto.file3D = e;
        },
        setSubImage(e) {
            if (e) {
                this.$set(this.imageDto.item, "file", e);
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = () => {
                    this.$set(this.imageDto.item, "base", reader.result);
                    this.imageModal = false;
                };
            } else {
                this.imageModal = false;
            }
        },
        addNewFiles(img) {
            this.productDto.files = [];
            img.forEach(element => {
                this.productDto.files.push(element);
            });
        },
        addProductUse() {
            this.productDto.uses.push({
                id: nullGuid,
                use: ""
            });
        },
        cancelFile(index) {
            this.productDto.files.splice(index, 1);
        },
        deleteImg(index) {
            this.productDto.images.splice(index, 1);
        },
        addMeasurement() {
            this.productDto.measurementCurrencies.push({
                height: 0,
                width: 0,
                length:0,
                capacityLiter: null,
                capacityKilo: null,
                dollarValue: 0,
                syrianValue: null,
                file: "",
                path: "",
                base: ""
            });
        },
        removeMeasurement(index) {
            this.productDto.measurementCurrencies.splice(index, 1);
        },
        addAddon(addon) {
            if (addon.attributeId && addon.dollarValue) {
                this.productDto.attributes.unshift({
                    id: nullGuid,
                    attributeId: addon.attributeId,
                    dollarValue: addon.dollarValue,
                    syrianValue: addon.syrianValue
                });
                this.addonDto = {
                    id: nullGuid,
                    attributeId: null,
                    dollarValue: 0,
                    syrianValue: null
                };
            }
            console.log(this.productDto.attributes);
        },
        setModalImage(a) {
            Object.assign(this.imageDto, a);
            this.imageModal = true;
        },
        removeAddon(addon) {
            this.productDto.attributes = this.productDto.attributes.filter(
                el => el.attributeId != addon.attributeId
            );
        },
        submitAddProduct() {
            this.$refs.productDto.validate().then(success => {
                if (
                    success &&
                    this.productDto.files.length &&
                    this.productDto.measurementCurrencies.length
                ) {
                    this.addProduct(this.productDto);
                }
            });
        },
        submitUpdateProduct() {
            this.$refs.productDto.validate().then(success => {
                if (
                    success &&
                    (this.productDto.files?.length ||
                        this.productDto.images.length) &&
                    this.productDto.measurementCurrencies.length
                ) {
                    this.updateProduct(this.productDto);
                }
            });
        }
    },
    beforeDestroy() {
        this.$refs.newImgsInput.clear();
    },
    watch: {
        imageModal() {
            this.$refs.imageModalRef && this.$refs.imageModalRef.clear();
        }
    }
};
</script>

<style scoped>
.product-img {
    height: 150px;
    width: 180px;
    object-fit: cover;
}
.AClass {
    left: 30px;
    position: absolute;
    z-index: 1;
}
.textCenter {
    text-align: -webkit-center;
}
</style>
<style>
.measRadius {
    border-radius: 5px;
    width: 100px;
}
@media (max-width: 767px) {
    .measRadius {
        width: 75px;
    }
}
model-viewer {
    background: rgb(219, 219, 219);
    background: radial-gradient(
        circle,
        rgba(219, 219, 219, 1) 0%,
        rgba(47, 47, 47, 1) 100%
    );
}

    
   
  
</style>
