var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_c('unicon',{staticClass:"mr-1",attrs:{"name":"bookmark","width":"18","height":"18"}}),_vm._v(" "+_vm._s(_vm.id != _vm.nullGuid ? "تفاصيل المنتج" : "إضافة منتج")+" ")],1)]),_c('ValidationObserver',{ref:"productDto"},[_c('b-form',[_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('EKInputText',{attrs:{"rules":[
                                    {
                                        type: 'required',
                                        message: 'اسم المنتج مطلوب'
                                    }
                                ],"label":"اسم المنتج","placeholder":"ادخل اسم المنتج","name":"name"},model:{value:(_vm.productDto.name),callback:function ($$v) {_vm.$set(_vm.productDto, "name", $$v)},expression:"productDto.name"}}),_c('EKInputSelect',{attrs:{"label":"تصنيفات المنتج","placeholder":"اختر تصنيفات للمنتج","options":_vm.categoriesList,"name":"categoriesIds","rules":[
                                    {
                                        type: 'required',
                                        message:
                                            'يرجى اختيار تصنيفات للمنتج'
                                    }
                                ],"multiple":""},model:{value:(_vm.productDto.categoriesIds),callback:function ($$v) {_vm.$set(_vm.productDto, "categoriesIds", $$v)},expression:"productDto.categoriesIds"}}),_c('label',{staticClass:"mt-2"},[_vm._v(" المواصفات ")]),_c('hr'),_c('EKInputTextarea',{attrs:{"label":"الوصف","rules":[
                                    {
                                        type: 'required',
                                        message: 'يرجى ملىء وصف المنتج'
                                    }
                                ],"placeholder":"ادخل وصف المنتج","name":"description","rows":"4"},model:{value:(_vm.productDto.description),callback:function ($$v) {_vm.$set(_vm.productDto, "description", $$v)},expression:"productDto.description"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('label',[_vm._v("استخدامات المنتج")]),_c('b-button',{staticClass:"rounded-circle p-75",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addProductUse()}}},[_c('unicon',{attrs:{"name":"plus","width":"18","height":"18","fill":"#fff"}})],1)],1),_c('b-list-group',{staticClass:"mb-1"},_vm._l((_vm.productDto.uses),function(use,index){return _c('div',{key:index,staticClass:"bg-white p-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('EKInputText',{key:'use' + '_value_' + index,staticClass:"w-100 mr-1",attrs:{"label":'الاستخدام ' + (index + 1),"placeholder":"ادخل الاستخدام","rules":[
                                                {
                                                    type: 'required',
                                                    message: 'الحقل مطلوب'
                                                }
                                            ],"name":'use' + '_value_' + index},model:{value:(use.use),callback:function ($$v) {_vm.$set(use, "use", $$v)},expression:"use.use"}}),_c('b-button',{staticClass:"rounded-circle p-75 mt-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.productDto.uses.splice(
                                                    index,
                                                    1
                                                )}}},[_c('unicon',{attrs:{"name":"minus","width":"18","height":"18","fill":"#fff"}})],1)],1)])}),0)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',[_c('label',{staticClass:"p-50 m-0"},[_vm._v("تصميم ثلاثي الابعاد للمنتج")]),_c('b-form-file',{on:{"input":_vm.uploadFbx}}),(!_vm.visible3D)?_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary","block":""},on:{"click":_vm.display3D}},[_vm._v(" عرض التصميم ثلاثي الأبعاد "),_c('unicon',{staticClass:"mx-1",attrs:{"fill":"#fff","name":"cube"}})],1):_vm._e(),(
                                        ((_vm.$store.getters['app/domainHost']) + "/" + (_vm.productDto.path3D)) &&
                                            _vm.visible3D
                                    )?_c('model-viewer',{staticClass:"w-100",staticStyle:{"height":"400px"},attrs:{"poster":_vm.productDto.images[0].path.replace(
                                            /^/,
                                            _vm.$store.getters[
                                                'app/domainHost'
                                            ] + '/'
                                        ),"color":"#000","src":((_vm.$store.getters['app/domainHost']) + "/" + (_vm.productDto.path3D)),"ar":"","ar-modes":"webxr scene-viewer quick-look","camera-controls":"","environment-image":"neutral","shadow-intensity":"1.8","exposure":"1.08","auto-rotate":""}},[_c('div',{staticClass:"progress-bar hide",attrs:{"slot":"progress-bar"},slot:"progress-bar"},[_c('div',{staticClass:"update-bar"})]),_c('button',{attrs:{"slot":"ar-button","id":"ar-button"},slot:"ar-button"},[_vm._v(" View in your space ")]),_c('div',{attrs:{"id":"ar-prompt"}},[_c('img',{attrs:{"src":"https://modelviewer.dev/shared-assets/icons/hand.png"}})])]):_vm._e()],1),_c('EKInputImage',{ref:"newImgsInput",attrs:{"label":"إضافة صور للمنتج","required":!_vm.productDto.images.length,"noDeleteBtn":"","mul":"","title":"أرفق الملفات هنا أو اضغط لتحميل","value":_vm.productDto.files},on:{"input":function($event){return _vm.addNewFiles($event)}},scopedSlots:_vm._u([{key:"preview",fn:function(ref){
                                        var previewBase = ref.previewBase;
return [_c('b-row',{staticClass:"px-2"},_vm._l((previewBase),function(img,index){return _c('b-col',{key:index,staticClass:"p-2",attrs:{"cols":"12","lg":"4"}},[_c('button',{staticClass:"close AClass mt-50",attrs:{"type":"button"},on:{"click":function($event){previewBase.splice(
                                                        index,
                                                        1
                                                    );
                                                    _vm.cancelFile(index);}}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("×")])]),_c('img',{staticClass:"img-fluid rounded product-img",attrs:{"src":img,"height":"190"}})])}),1)]}}])}),(_vm.productDto.images.length)?_c('div',{staticClass:"my-3"},[_c('h5',[_vm._v("صور المنتج الحالية")]),_c('b-row',{staticClass:"mx-50",staticStyle:{"border":"dashed 2px #196da7"}},_vm._l((_vm.productDto.images),function(img,index){return _c('b-col',{key:index,staticClass:"p-2",attrs:{"cols":"12","lg":"4"}},[_c('button',{staticClass:"close AClass mt-50",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteImg(index)}}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("×")])]),_c('img',{staticClass:"img-fluid rounded product-img",attrs:{"src":img.path.replace(
                                                    /^/,
                                                    _vm.$store.getters[
                                                        'app/domainHost'
                                                    ] + '/'
                                                ),"height":"190"}})])}),1)],1):_vm._e(),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('EKInputText',{attrs:{"rules":[
                                            {
                                                type: 'required',
                                                message:
                                                    'الحد الأدنى للطلب مطلوب'
                                            },
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"label":"الحد الأدنى للطلب (داخلي","placeholder":"ادخل الحد الأدنى للطلب)","name":"limit","type":"number"},model:{value:(_vm.productDto.limit),callback:function ($$v) {_vm.$set(_vm.productDto, "limit", $$v)},expression:"productDto.limit"}}),_c('EKInputText',{attrs:{"rules":[
                                            {
                                                type: 'required',
                                                message:
                                                    'الحد الأدنى للطلب مطلوب'
                                            },
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"label":"الحد الأدنى للطلب (الخارجي)","placeholder":"ادخل الحد الأدنى للطلب","name":"limitOtside","type":"number"},model:{value:(_vm.productDto.limitOutside),callback:function ($$v) {_vm.$set(_vm.productDto, "limitOutside", $$v)},expression:"productDto.limitOutside"}})],1),_c('b-col',{staticClass:"d-flex mt-2",attrs:{"cols":"12","lg":"6"}},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.productDto.foodSuitability),callback:function ($$v) {_vm.$set(_vm.productDto, "foodSuitability", $$v)},expression:"productDto.foodSuitability"}}),_c('label',[_vm._v("يصلح للإستخدام الغذائي")])],1)],1),_c('b-row',{staticClass:"align-items-center pt-2"},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('EKInputSelect',{attrs:{"label":"الإضافات","placeholder":"اختر إضافة للمنتج","options":_vm.addonsList.filter(
                                                function (addon) { return _vm.productDto.attributes.findIndex(
                                                        function (attr) { return attr.attributeId ==
                                                            addon.id; }
                                                    ) == -1; }
                                            ),"name":"attributeId"},model:{value:(_vm.addonDto.attributeId),callback:function ($$v) {_vm.$set(_vm.addonDto, "attributeId", $$v)},expression:"addonDto.attributeId"}})],1),_c('b-col',{staticClass:"pl-0",attrs:{"cols":"6","lg":"3"}},[_c('EKInputText',{staticClass:"textCenter",attrs:{"label":"السعر بالعملة الأجنبية","name":"dollarValue","fieldClass":"measRadius","type":"number"},model:{value:(_vm.addonDto.dollarValue),callback:function ($$v) {_vm.$set(_vm.addonDto, "dollarValue", $$v)},expression:"addonDto.dollarValue"}})],1),_c('b-col',{staticClass:"pt-2 text-center text-lg-left",attrs:{"cols":"12","lg":"3"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"rounded p-75",attrs:{"title":"إضافة جديدة","variant":"primary"},on:{"click":function($event){return _vm.addAddon(_vm.addonDto)}}},[_c('unicon',{attrs:{"name":"plus","width":"18","height":"18","fill":"#fff"}})],1)],1)],1),_vm._l((_vm.productDto.attributes),function(addon,index){return _c('b-row',{key:index,staticClass:"align-items-center"},[_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12","lg":"3"}},[_c('EKInputSelect',{attrs:{"placeholder":"اختر إضافة للمنتج","options":_vm.addonsList,"name":'attributeId' + index,"rules":[
                                            {
                                                type: 'required',
                                                message:
                                                    'يرجى اختيار إضافة للمنتج'
                                            }
                                        ],"disabled":""},model:{value:(addon.attributeId),callback:function ($$v) {_vm.$set(addon, "attributeId", $$v)},expression:"addon.attributeId"}})],1),_c('b-col',{staticClass:"pl-0",attrs:{"cols":"6","lg":"3"}},[_c('EKInputText',{staticClass:"textCenter",attrs:{"rules":[
                                            {
                                                type: 'required',
                                                message: 'الحقل مطلوب'
                                            },
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"name":'dollarValue' + index,"fieldClass":"measRadius","type":"number"},model:{value:(addon.dollarValue),callback:function ($$v) {_vm.$set(addon, "dollarValue", $$v)},expression:"addon.dollarValue"}})],1),_c('b-col',{staticClass:"text-center text-lg-left",attrs:{"cols":"12","lg":"3"}},[_c('b-button',{staticClass:"rounded p-75",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.removeAddon(addon)}}},[_c('unicon',{attrs:{"name":"minus","width":"18","height":"18","fill":"#fff"}})],1)],1)],1)})],2),_c('b-col',{attrs:{"cols":"12"}},[_c('hr'),_c('div',{staticClass:"d-flex align-items-center justify-content-between my-2"},[_c('h3',[_vm._v("جدول مقاسات المنتج")]),_c('b-col',{staticClass:"d-flex mt-2",attrs:{"cols":"12","lg":"6"}},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.productDto.isCircleShape),callback:function ($$v) {_vm.$set(_vm.productDto, "isCircleShape", $$v)},expression:"productDto.isCircleShape"}}),_c('label',[_vm._v("المنتج اسطواني؟")])],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"rounded-circle p-75",attrs:{"title":"إضافة مقاس جديد","variant":"primary"},on:{"click":_vm.addMeasurement}},[_c('unicon',{attrs:{"name":"plus","width":"18","height":"18","fill":"#fff"}})],1)],1),_c('b-table',{attrs:{"responsive":"","striped":"","borderless":"","items":_vm.productDto.measurementCurrencies,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(image)",fn:function(data){return [_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"rounded-circle",staticStyle:{"object-fit":"cover"},attrs:{"title":"إضغط لتعديل صورة المقاس","src":data.item.base
                                                ? data.item.base
                                                : data.item.path
                                                ? _vm.$store.getters[
                                                      'app/domainHost'
                                                  ] +
                                                  '/' +
                                                  data.item.path
                                                : '/media/placeholder/image-placeholder.jpg',"width":"50","height":"50"},on:{"click":function($event){return _vm.setModalImage(data)}}}),(
                                            !data.item.path &&
                                                !data.item.base
                                        )?_c('small',{staticClass:"text-danger text-nowrap"},[_vm._v("الصورة إجبارية")]):_vm._e()]}},{key:"cell(length)",fn:function(data){return [_c('EKInputText',{staticClass:"textCenter",attrs:{"rules":[
                                            {
                                                type: 'required',
                                                message: 'الحقل مطلوب'
                                            },
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"fieldClass":"measRadius","name":'length' + data.index,"type":"number"},model:{value:(data.item.length),callback:function ($$v) {_vm.$set(data.item, "length", $$v)},expression:"data.item.length"}})]}},{key:"cell(width)",fn:function(data){return [_c('EKInputText',{staticClass:"textCenter",attrs:{"rules":[
                                            {
                                                type: 'required',
                                                message: 'الحقل مطلوب'
                                            },
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"name":'width' + data.index,"fieldClass":"measRadius","type":"number"},model:{value:(data.item.width),callback:function ($$v) {_vm.$set(data.item, "width", $$v)},expression:"data.item.width"}})]}},{key:"cell(height)",fn:function(data){return [_c('EKInputText',{staticClass:"textCenter",attrs:{"rules":[
                                            {
                                                type: 'required',
                                                message: 'الحقل مطلوب'
                                            },
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"fieldClass":"measRadius","name":'height' + data.index,"type":"number"},model:{value:(data.item.height),callback:function ($$v) {_vm.$set(data.item, "height", $$v)},expression:"data.item.height"}})]}},{key:"cell(capacityLiter)",fn:function(data){return [_c('EKInputText',{staticClass:"textCenter",attrs:{"name":'capacityLiter' + data.index,"rules":[
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"fieldClass":"measRadius","type":"number"},model:{value:(data.item.capacityLiter),callback:function ($$v) {_vm.$set(data.item, "capacityLiter", $$v)},expression:"data.item.capacityLiter"}})]}},{key:"cell(capacityKilo)",fn:function(data){return [_c('EKInputText',{staticClass:"textCenter",attrs:{"name":'capacityKilo' + data.index,"rules":[
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"fieldClass":"measRadius","type":"number"},model:{value:(data.item.capacityKilo),callback:function ($$v) {_vm.$set(data.item, "capacityKilo", $$v)},expression:"data.item.capacityKilo"}})]}},{key:"cell(dollarValue)",fn:function(data){return [_c('EKInputText',{staticClass:"textCenter",attrs:{"rules":[
                                            {
                                                type: 'required',
                                                message: 'الحقل مطلوب'
                                            },
                                            {
                                                type: 'min_value:0',
                                                message: 'ادخل قيمة موجبة'
                                            }
                                        ],"name":'dollarValue' + data.index,"fieldClass":"measRadius","type":"number"},model:{value:(data.item.dollarValue),callback:function ($$v) {_vm.$set(data.item, "dollarValue", $$v)},expression:"data.item.dollarValue"}})]}},{key:"cell(delete)",fn:function(data){return [_c('span',{staticStyle:{"padding":"4px 22px !important","cursor":"pointer"},on:{"click":function($event){return _vm.removeMeasurement(data.index)}}},[_c('unicon',{attrs:{"name":"trash-alt","fill":"#000","width":"16"}})],1)]}}])}),_c('div',{staticClass:"text-center"},[(
                                        !_vm.productDto.measurementCurrencies
                                            .length
                                    )?_c('small',{staticClass:"text-danger"},[_vm._v("يرجى إضافة مقاس واحد على الأقل")]):_vm._e()])],1)],1)],1),_c('b-modal',{attrs:{"title":"تعديل صورة المقاس"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setSubImage(_vm.file)}}},[_vm._v("تم")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.imageModal = false}}},[_vm._v("إلغاء")])]},proxy:true}]),model:{value:(_vm.imageModal),callback:function ($$v) {_vm.imageModal=$$v},expression:"imageModal"}},[(_vm.imageDto.item)?_c('EKInputImage',{ref:"imageModalRef",attrs:{"value":_vm.imageDto.item.base
                                ? _vm.imageDto.item.base
                                : _vm.imageDto.item.path
                                ? _vm.$store.getters['app/domainHost'] +
                                  '/' +
                                  _vm.imageDto.item.path
                                : '/media/placeholder/image-placeholder.jpg'},on:{"input":function($event){_vm.file = $event}}}):_vm._e()],1)],1),_c('b-card-footer',{staticClass:"py-1 d-flex"},[(_vm.id != _vm.nullGuid)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitUpdateProduct()}}},[_vm._v(" تعديل ")]):_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitAddProduct()}}},[_vm._v(" إضافة")]),_c('b-button',{attrs:{"variant":"outline-primary","to":"./"}},[_vm._v("تراجع")]),(_vm.id != _vm.nullGuid)?_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteProduct(_vm.productDto.id)}}},[_vm._v("حذف")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }